import React, { useContext, useState } from 'react'
import { Redirect, Route, Switch, useLocation, NavLink } from 'react-router-dom'
import { Button, Icon } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'

import UsersPage from './UsersPage'
import SettingsPage from './SettingsPage'
import DeparturesPage from './DeparturesPage'
import ResiduePage from './ResiduePage'
import QueuePage from './QueuePage'
import DriversPage from './DriversPage'
import RequestPage from './RequestPage'
import CarPage from './CarPage'

import { UserContext } from 'context'
import Page404 from './Page404'

import pageList from 'constants/rolePages.json'
import './MainPage.css'

function MainPage (props) {
  const [hideMenu, setHideMenu] = useState(true)

  const { intl } = props
  const { currentUser } = useContext(UserContext)

  const location = useLocation().pathname
  const tabs = pageList[currentUser?.role]

  const getPageName = () => {
    let name = tabs.find((element) => location === element.url)?.name || 'page404'

    return name
  }

  // Переходимо на першу вкладку із переліку вкладок
  if (props.location.pathname === '/') 
    return (<Redirect push to={tabs[0].url} />)

  return (
    <div className="mainPage">
      <div className={"sidebar" + (hideMenu ? ' hide' : '')} onClick={() => setHideMenu(true)}>
        <div className="tabs">
          {tabs.map(tab => 
            <NavLink 
              exact
              key={tab.url} 
              to={tab.url} 
              className='btn'
              activeClassName='active'
            >
              {intl.formatMessage({ id: tab.name })}
            </NavLink>
          )}
        </div>
      </div>
      <div className="content">
        <h1>{intl.formatMessage({ id: getPageName() })}</h1>
        <hr />
        <Switch>
          <Route exact path="/users" component={UsersPage}/>
          <Route exact path="/settings" component={SettingsPage}/>
          <Route exact path="/departures" component={DeparturesPage}/>
          <Route exact path="/residues" component={ResiduePage}/>
          <Route exact path="/queue" component={QueuePage}/>
          <Route exact path="/drivers" component={DriversPage}/>
          <Route exact path="/request" component={RequestPage}/>
          <Route exact path="/cars" component={CarPage}/>
          <Route component={Page404} />
        </Switch>
      </div>
      <Button className={"toggleMenu" + (hideMenu ? ' hide' : '')} onClick={() => setHideMenu(!hideMenu)} icon>
        <Icon name="bars"/> 
        Menu
      </Button>
    </div>
    )
}

export default injectIntl(MainPage)
