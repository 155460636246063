import React, {useContext, useState} from 'react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'
import ModalWindow from '../../../ModalWindow'
import reactStringReplace from 'react-string-replace';
import { Button, Icon } from 'semantic-ui-react';

import api from 'actions/api'

import './DeleteModal.css'
import doRequest from 'actions/doRequest';

function DeleteModal (props) {
    const { user, onDeleteUser, disabled, intl } = props
    const logout = useContext(UserContext)

    const translates = {
        deleteUserTitle: intl.formatMessage({ id: 'title.deleting' }),
        message: intl.formatMessage({ id: 'title.deleteUser' }),
        cancel: intl.formatMessage({ id: 'btn.cancel' }),
        delete: intl.formatMessage({ id: 'btn.delete' }),
    }

    const [open, setOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const { pushNotify } = useContext(NotifyContext)

    const deleteUser = () => {
        if (!deleting) {
            setDeleting(true)

            const request = doRequest(api.user.delete(user.id), pushNotify, logout)
            request.ready.then(data => {
                pushNotify('info', 'title.success', 'msg.deleteUserSuccess')
                onDeleteUser(Number(data?.id))
            }).catch(console.error)
            .finally(() => {
                setDeleting(false)
            })
        }
    }

    //TODO: Deleting can be closed when request in work

    const getButtons = () => [
        { title: translates.cancel, onClick: () => setOpen(false), loading: false },
        { title: translates.delete, onClick: deleteUser, color: 'red', loading: deleting }
    ]

    return (
        <ModalWindow 
            iconType="warning"
            open={open && !disabled}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            title={translates.deleteUserTitle} 
            trigger={
                <Button disabled={disabled} className="deleteButton" icon><Icon name="trash alternate"/><span>{translates.delete}</span></Button> 
            }
            actions={getButtons()}
        >
            {reactStringReplace(translates.message, '%s', (match, i) => (<b key={i}>{user.login}</b>))}
        </ModalWindow>
    )
}

export default injectIntl(DeleteModal)
