import React, { useContext, useRef, useState, useMemo } from 'react'
import ModalWindow from 'components/ModalWindow'
import { Button, Icon, Loader, Table } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'

import api from 'actions/api'

import './DepartureInfoModal.css'
import doRequest from 'actions/doRequest'

import { fields } from '../../../../constants/config.json'
const show = fields?.departures?.waybill || {}

function DepartureInfoModal(props) {
    const { departureId, intl } = props
    const { logout } = useContext(UserContext)

    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [departure, setDeparture] = useState({})
    const abortRequestRef = useRef(null)

    const { pushNotify } = useContext(NotifyContext)

    const translates = useMemo(() => ({
        detail: intl.formatMessage({ id: 'btn.detail' }),
        ok: intl.formatMessage({ id: 'btn.ok' }),
        waybill: intl.formatMessage({ id: 'title.waybill' }),
        departureInfoTitle: intl.formatMessage({ id: 'title.waybillInfo' }),
        dateDoc: intl.formatMessage({ id: 'title.dateDoc' }),
        dateFirstWeight: intl.formatMessage({ id: 'title.dateFirstWeight' }),
        dateBeginingLaboratoryAnalysis: intl.formatMessage({ id: 'title.dateBeginingLaboratoryAnalysis' }),
        dateEndLaboratoryAnalysis: intl.formatMessage({ id: 'title.dateEndLaboratoryAnalysis' }),
        dateTaring: intl.formatMessage({ id: 'title.dateTaring' }),
        incomingNumber: intl.formatMessage({ id: 'title.incomingNumber' }),
        car: intl.formatMessage({ id: 'title.car' }),
        driver: intl.formatMessage({ id: 'title.driver' }),
        status: intl.formatMessage({ id: 'title.status' }),
        elevator: intl.formatMessage({ id: 'title.elevator' }),
        product: intl.formatMessage({ id: 'title.product' }),
        unloadingDecision: intl.formatMessage({ id: 'title.unloadingDecision' }),
        returnSupplier: intl.formatMessage({ id: 'title.returnSupplier' }),
        nomenclature: intl.formatMessage({ id: 'title.nomenclature' }),
        grossWeight: intl.formatMessage({ id: 'title.grossWeight' }),
        tareWeight: intl.formatMessage({ id: 'title.tareWeight' }),
        incomingQuantity: intl.formatMessage({ id: 'title.incomingQuantity' }),
        quantity: intl.formatMessage({ id: 'title.quantity' }),
        netWeight: intl.formatMessage({ id: 'title.netWeight' }),
        comment: intl.formatMessage({ id: 'title.comments' }),
        lab: intl.formatMessage({ id: 'title.labAnalysis' }),
        firstAnalysis: intl.formatMessage({ id: 'title.firstAnalysis' }),
        mainAnalysis: intl.formatMessage({ id: 'title.mainAnalysis' }),
    }), [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const getButtons = () => [
        { title: translates.ok, onClick: () => setOpen(false), disabled: false, loading: false },
    ]

    const openModal = () => {
        setLoading(true)
        
        const req = doRequest(api.departure.departure(departureId), pushNotify, logout)
        abortRequestRef.current = req.abort

        req.ready.then(data => {
            setDeparture(data)
        }).catch(console.error)
        .finally(() => {
            setLoading(false)
        })

        setOpen(true)
    }

    const generateTableRow = (title, value, units = null) => { //TODO: Зробити нормальні key
        if (!value) return null;

        return (
            <Table.Row key={title}> 
                <Table.Cell className="departureTitle"><b>{title}:</b></Table.Cell>
                <Table.Cell className="departureValue">{value} {units || null}</Table.Cell>
            </Table.Row>
        )
    }

    return (
        <ModalWindow 
            iconType="info"
            title={translates.departureInfoTitle} 
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => openModal()}
            trigger={
                <Button className="infoButton" disabled={!departureId} icon><Icon name="info circle"/><span>{translates.detail}</span></Button> 
            }
            scrolling={!loading}
            actions={getButtons()}
        >
            {loading ?
                <Loader active inline="centered"/>
            :    
                <div className="departureInfo">
                    <Table basic="very" celled collapsing unstackable>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell className="departureHeader"><b>{translates.waybill}:</b></Table.Cell>
                            </Table.Row>
                            {generateTableRow(translates.incomingNumber, departure?.incomingNumber)}
                            {generateTableRow(translates.dateDoc, departure?.dateDoc)}
                            {generateTableRow(translates.dateFirstWeight, departure?.dateFirstWeight)}
                            {generateTableRow(translates.dateBeginingLaboratoryAnalysis, departure?.dateBeginingLaboratoryAnalysis)}
                            {generateTableRow(translates.dateEndLaboratoryAnalysis, departure?.dateEndLaboratoryAnalysis)}
                            {generateTableRow(translates.dateTaring, departure?.dateTaring)}
                            {generateTableRow(translates.car, departure?.car)}
                            {generateTableRow(translates.unloadingDecision, intl.formatMessage({ id: `title.${departure.unloadingDecision}` }))}
                            {generateTableRow(translates.returnSupplier, intl.formatMessage({ id: `title.${departure.returnSupplier}` }))}
                            {show.comments && generateTableRow(translates.comment, departure?.comments)}
                            {departure.nomenclatures?.map((nomenclature, index) => (
                                <React.Fragment key={index}>
                                    <Table.Row>
                                        <Table.Cell className="departureHeader"><b>{translates.nomenclature}:</b></Table.Cell>
                                    </Table.Row>
                                    {generateTableRow(translates.product, nomenclature.cropName)}
                                    {generateTableRow(translates.grossWeight, nomenclature.grossWeight)}
                                    {generateTableRow(translates.tareWeight, nomenclature.tareWeight)}
                                    {generateTableRow(translates.incomingQuantity, nomenclature.incomingQuantity)}
                                    {generateTableRow(translates.quantity, nomenclature.quantity)}
                                    {show.netWeight && generateTableRow(translates.netWeight, nomenclature.netWeight)}
                                    <Table.Row>
                                        <Table.Cell className="departureHeader"><b>{translates.lab}:</b></Table.Cell>
                                    </Table.Row>
                                    {Object.keys(nomenclature.lab).map(key => (
                                        <React.Fragment key={key}>
                                            <Table.Row>
                                                <Table.Cell className="departureHeader"><b>{intl.formatMessage({ id: `title.${key}` })}:</b></Table.Cell>
                                            </Table.Row>
                                            {nomenclature.lab[key].map((analysis, index) => generateTableRow(analysis.name, analysis.value, analysis.units))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </Table.Body>
                    </Table>
                </div>
            }
        </ModalWindow>
    )
}

export default injectIntl(DepartureInfoModal)