import React, { useState, useEffect, useContext, useMemo } from 'react'
import { Dropdown, Table, TableBody, Loader } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext, PdaContext } from 'context'
import { Card, CardActions, CardBody, CardIcon } from '../Card'
import Filter from '../Filter'

import DepartureInfoModal from './DepartureInfoModal'
import Label from '../Label'
import Paginator from '../Paginator'

import api from 'actions/api'
import doRequest from 'actions/doRequest'

import { cardsOnPage, orderStepList, orderRolesAccess } from 'constants/config.json'

import './DeparturesPage.css'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

function DeparturesPage (props) {
    const { intl } = props
    const [loading, setLoading] = useState(false)
    const [departureList, setDepartureList] = useState([])
    const [options, setOptions] = useState({})
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [sortBy, setSortBy] = useState('byNewest')
    const [filter, setFilter] = useState({})

    const { isPda } = useContext(PdaContext)
    const { pushNotify } = useContext(NotifyContext)
    const { logout, currentUser } = useContext(UserContext)

    const translates = useMemo(() => ({
        dateOrder: intl.formatMessage({ id: 'title.date' }),
        elevator: intl.formatMessage({ id: 'title.elevator' }),
        counterparty: intl.formatMessage({ id: 'title.counterparty' }),
        driver: intl.formatMessage({ id: 'title.driver' }),
        car: intl.formatMessage({ id: 'title.car' }),
        type: intl.formatMessage({ id: 'title.type' }),
        step: intl.formatMessage({ id: 'title.step' }),
        notFound: intl.formatMessage({ id: 'title.recordNotFound' }),
        byCarrier: intl.formatMessage({ id: 'sort.byCarrier' }),
        byNewest: intl.formatMessage({ id: 'sort.byNewest' }),
        byOldest: intl.formatMessage({ id: 'sort.byOldest' }),
        byElevator: intl.formatMessage({ id: 'sort.byElevator' }),
        byCar: intl.formatMessage({ id: 'sort.byCar' }),
        byDriver: intl.formatMessage({ id: 'sort.byDriver' }),
        byType: intl.formatMessage({ id: 'sort.byType' }),
        byStatus: intl.formatMessage({ id: 'sort.byStep' }),
        byWaybill: intl.formatMessage({ id: 'sort.byWaybill' }),
        filter: intl.formatMessage({ id: 'filter.filter' })
    }), [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const filterList = useMemo(() => {
        return [
        {
            title: translates.dateOrder,
            type: 'daterange',
            name: 'dateDoc'
        },
        {
            title: translates.elevator,
            type: 'dropdown',
            name: 'elevator',
            options: options.elevators
        },
        {
            title: translates.car,
            type: 'dropdown',
            name: 'car',
            options: options.cars
        },
        {
            title: translates.driver,
            type: 'dropdown',
            name: 'driver',
            options: options.drivers
        },
        {
            title: translates.step,
            type: 'dropdown',
            name: 'step',
            options: orderStepList.map(item => { return { key: item, text: intl.formatMessage({ id: `orderstep.${item}`}), value: item }})
        }
    ]}, [intl.locale, options], options.elevators, options.cars, options.drivers) // eslint-disable-line react-hooks/exhaustive-deps

    const getOptions = () => {
        setLoading(true)
        let requests = []
        
        requests.push(doRequest(api.elevators.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.cars.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.driver.list(), pushNotify, logout).ready)

        Promise.all(requests).then(result => {
            const res = result.reduce((acc, data) => {
                if (data.elevators) return { ...acc, elevators: data.elevators.map((elevator) => { return { key: elevator.id, value: elevator.id, text: elevator.name }}) };
                if (data.cars) return { ...acc, cars: data.cars.map((car) => { return { key: car.id, value: car.id, text: car.regPlate }}) };
                if (data.drivers) return { ...acc, drivers: data.drivers.map((driver) => { return { key: driver.id, value: driver.id, text: `${driver.firstName} ${driver.lastName} ()` }}) };
                return acc
            }, {})
            
            setOptions({ ...res })
        }).catch(console.error)
        .finally(() => setLoading(false))
    }

    const sortOptions = [
        { key: 1, text: translates.byNewest, value: "byNewest" },
        { key: 2, text: translates.byOldest, value: "byOldest" },
        { key: 3, text: translates.byElevator, value: "byElevator" },
        { key: 4, text: translates.byCar, value: "byCar" },
        { key: 5, text: translates.byDriver, value: "byDriver" },
        { key: 6, text: translates.byType, value: "byType" },
        { key: 7, text: translates.byStatus, value: "byStep" },
        { key: 8, text: translates.byWaybill, value: "byWaybill" }
    ]

    const loadDepartures = (page) => {
        setDepartureList([])
        setLoading(true)

        const offset = cardsOnPage * (page - 1), count = cardsOnPage

        doRequest(api.departure.list(offset, count, filter.dateDoc?.start?.toDateString(), filter.dateDoc?.end?.toDateString(), filter.elevator, filter.car, filter.driver, filter.type, filter.step, filter.waybill, sortBy), pushNotify, logout).ready.then(data => {
            const { departures, count } = data
            const pages = Math.ceil(count / cardsOnPage)

            setDepartureList(departures)                

            if (page > pages && pages > 0) {
                loadDepartures(pages)
            } else {
                setPageCount(pages)
                setPage(page)
            }
        }).catch(e => {
            setDepartureList([])
        }).finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        getOptions()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loading) return
        loadDepartures(1)
    }, [sortBy, filter]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="topPanel">
                <Filter filter={filter} disabled={loading} setFilter={e => { setFilter(e) }} filterList={filterList}/>
                <Dropdown options={sortOptions} selection icon="sort" value={sortBy} onChange={(e, { value }) => setSortBy(value)}/>
            </div>
            { loading ? 
                <Loader className={"residue-loader"} active inline="centered" />
            :
                departureList.length === 0 ?
                    <Label text={translates.notFound} />
                :
                    isPda ?
                        departureList.map((departure) => (
                            <Card key={departure.id} className="departureCard">
                                <CardIcon name="truck" />
                                <CardBody>
                                    <span><b>{translates.dateOrder}:</b> {departure.dateOrder}</span>
                                    {orderRolesAccess.includes(currentUser.role) ? <span><b>{translates.counterparty}:</b> {departure.counterparty || '---'}</span> : ''}
                                    <span><b>{departure.elevator ? translates.elevator : translates.counterparty}:</b> {departure.elevator || departure.counterparty}</span>
                                    <span><b>{translates.car}:</b> {departure.car || '---'}</span>
                                    <span><b>{translates.driver}:</b> {departure.driver || '---'}</span>
                                    <span><b>{translates.type}:</b> {intl.formatMessage({ id: `ordertype.${departure.departureType}`})}</span>
                                    <span><b>{translates.step}:</b> {intl.formatMessage({ id: `orderstep.${departure.step}`})}</span>
                                </CardBody>
                                <CardActions>
                                    <DepartureInfoModal departureId={departure.waybillId} />
                                </CardActions>
                            </Card>
                        ))
                    :
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{translates.dateOrder}</Table.HeaderCell>
                                    {orderRolesAccess.includes(currentUser.role) ? <Table.HeaderCell>{translates.counterparty}</Table.HeaderCell> : ''}
                                    <Table.HeaderCell>{translates.elevator}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.car}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.driver}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.type}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.step}</Table.HeaderCell>
                                    <Table.HeaderCell />
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {
                                    departureList.map((departure) => (
                                        <Table.Row key={departure.id} className={departure.crash ? 'red' : ''}>
                                            <Table.Cell>{departure.dateOrder}</Table.Cell>
                                            {orderRolesAccess.includes(currentUser.role) ? <Table.Cell>{departure.counterparty || '---'}</Table.Cell> : ''}
                                            <Table.Cell>{departure.elevator}</Table.Cell>
                                            <Table.Cell>{departure.car || '---'}</Table.Cell>
                                            <Table.Cell>{departure.driver || '---'}</Table.Cell>
                                            <Table.Cell>{intl.formatMessage({ id: `ordertype.${departure.departureType}`})}</Table.Cell>
                                            <Table.Cell>{intl.formatMessage({ id: `orderstep.${departure.step}`})}</Table.Cell>
                                            <Table.Cell className="actionColumn"><DepartureInfoModal departureId={departure.waybillId} /></Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </TableBody>
                        </Table>
            }
            <Paginator pageCount={pageCount} page={page} setPage={loadDepartures} disabled={loading} />
        </React.Fragment>
    )
}

export default injectIntl(DeparturesPage)
