import React, { useContext, useState, useEffect, useMemo } from 'react'
import { UserContext, NotifyContext } from 'context'
import { Button, Dropdown, Loader } from 'semantic-ui-react'

import api from '../../../actions/api'

import './RequestPage.css'
import { injectIntl } from 'react-intl'
import doRequest from 'actions/doRequest'

function RequestPage (props) {
    const { intl } = props

    const [loading, setLoading] = useState(true)
    const [sending, setSending] = useState(false)
    const [options, setOptions] = useState({})
    const [values, setValues] = useState({})
    const [requestType, setRequestType] = useState('unloading')

    const { logout } = useContext(UserContext)
    const { pushNotify } = useContext(NotifyContext)

    const translates = useMemo(() => ({
        elevator: intl.formatMessage({ id: "title.elevator" }),
        nomenclature: intl.formatMessage({ id: "title.nomenclature" }),
        car: intl.formatMessage({ id: "title.car" }),
        driver: intl.formatMessage({ id: "title.driver" }),
        requestType: intl.formatMessage({ id: "title.type" }),
        noResult: intl.formatMessage({ id: "title.resultNotFound" }),
        sendRequest: intl.formatMessage({ id: "btn.send" }),
        uploading: intl.formatMessage({ id: 'ordertype.uploading' }),
        unloading: intl.formatMessage({ id: 'ordertype.unloading' }),
    }), [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const requestTypeOptions = [
        { key: 0, text: translates.unloading, value: 'unloading'},
        { key: 1, text: translates.uploading, value: 'uploading'}
    ]
    

    const getOptions = () => {
        setLoading(true)
        let requests = []
        
        requests.push(doRequest(api.elevators.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.cars.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.nomenclatures.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.driver.list(), pushNotify, logout).ready)

        Promise.all(requests).then(result => {
            const res = result.reduce((acc, data) => {
                if (data.elevators) return { ...acc, elevators: data.elevators.map((elevator) => { return { key: elevator.id, value: elevator.id, text: elevator.name }}) };
                if (data.nomenclatures) return { ...acc, nomenclatures: data.nomenclatures.map((nomenclature) => { return { key: nomenclature.id, value: nomenclature.id, text: nomenclature.name }}) };
                if (data.cars) return { ...acc, cars: data.cars.map((car) => { return { key: car.id, value: car.id, text: car.regPlate }}) };
                if (data.drivers) return { ...acc, drivers: data.drivers.map((driver) => { return { key: driver.id, value: driver.id, text: `${driver.firstName} ${driver.lastName}` }}) };
                return acc;
            }, {})
            
            setOptions({ ...res })
        }).catch(console.error)
        .finally(() => setLoading(false))
    }

    useEffect(getOptions, []) // eslint-disable-line react-hooks/exhaustive-deps

    const sendRequest = () => {
        const request = {
            elevator: typeof values.elevator === 'number' ? values.elevator : undefined,
            nomenclatures: typeof values.nomenclatures === 'object' ? values.nomenclatures : undefined,
            car: typeof values.car === 'number' ? values.car : undefined,
            driver: typeof values.driver === 'number' ? values.driver : undefined,
            type: requestType
        }

        if (!request.elevator) {
            pushNotify('error', 'title.error', 'msg.elevatorMustBeFilled', 2000)
            return
        }
        if (!request.nomenclatures) {
            pushNotify('error', 'title.error', 'msg.nomenclatureMustBeFilled', 2000)
            return
        }

        setSending(true)
        doRequest(api.request.send(request), pushNotify, logout).ready.then(res => {
            pushNotify('success', 'title.success', 'msg.sendRequstSuccess', 2000)
            setValues({
                elevator: null,
                nomenclature: null,
                car: null,
                driver: null
            })
        }).catch(console.error)
        .finally(() => {
            setSending(false)
        })
    }

    return (
        loading ?
            <Loader className={"page-loader"} active inline="centered" />
        :
            <div className="requestForm">
                <div className="requestItem">
                    <div>{translates.elevator}<b>*</b>:</div>
                    <Dropdown 
                        search 
                        fluid 
                        selection 
                        placeholder={translates.elevator} 
                        options={options.elevators} 
                        disabled={sending}
                        value={values.elevator} 
                        noResultsMessage={translates.noResult}
                        onChange={(e, { value }) => setValues({ ...values, elevator: value})}
                    />
                </div>
                <div className="requestItem">
                    <div>{translates.nomenclature}<b>*</b>:</div>
                    <Dropdown 
                        search 
                        fluid 
                        selection 
                        multiple
                        placeholder={translates.nomenclature} 
                        options={options.nomenclatures}
                        disabled={sending}
                        value={values.nomenclatures} 
                        lazyLoad
                        noResultsMessage={translates.noResult}
                        onChange={(e, { value }) => setValues({ ...values, nomenclatures: value})}
                    />
                </div>
                <div className="requestItem">
                    <div>{translates.car}<b></b>:</div>
                    <Dropdown 
                        clearable
                        search 
                        fluid 
                        selection 
                        lazyLoad
                        placeholder={translates.car} 
                        options={options.cars}
                        disabled={sending}
                        value={values.car} 
                        noResultsMessage={translates.noResult}
                        onChange={(e, { value }) => setValues({ ...values, car: value})}
                    />
                </div>
                <div className="requestItem">
                    <div>{translates.driver}:</div>
                    <Dropdown 
                        clearable 
                        search 
                        fluid 
                        selection 
                        lazyLoad
                        placeholder={translates.driver} 
                        options={options.drivers}
                        disabled={sending}
                        value={values.driver} 
                        noResultsMessage={translates.noResult}
                        onChange={(e, { value }) => setValues({ ...values, driver: value})}
                    />
                </div>
                <div className="requestItem">
                    <div>{translates.requestType}:</div>
                    <Dropdown 
                        fluid 
                        selection 
                        options={requestTypeOptions}
                        disabled={sending}
                        value={requestType} 
                        noResultsMessage={translates.noResult}
                        onChange={(e, { value }) => setRequestType(value)}
                    />
                </div>
                <div className="requestItem">
                    <Button onClick={sendRequest} disabled={sending} loading={sending}>{translates.sendRequest}</Button>
                </div>
            </div>
    )
}

export default injectIntl(RequestPage)