import React, { useContext, useEffect, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { Button, Dropdown, Input } from 'semantic-ui-react'

import { UserContext, NotifyContext } from 'context'

import './CarPage.css'
import api from '../../../actions/api'
import doRequest from 'actions/doRequest'

function CarPage(props) {
    const { intl } = props
    const [car, setCar] = useState()
    const [rfid, setRfid] = useState('')
    const [loading, setLoading] = useState(true)
    const [saving, setSaving] = useState(false)
    const [options, setOptions] = useState([])

    const { logout } = useContext(UserContext)
    const { pushNotify } = useContext(NotifyContext)

    const translates = useMemo(() => ({
        rfid: intl.formatMessage({ id: "msg.rfid" }),
        selectCar: intl.formatMessage({ id: "msg.selectCar" }),
        carNotFound: intl.formatMessage({ id: "msg.carNotFound" }),
        setRfid: intl.formatMessage({ id: "btn.setRfid" })
    }), [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const getOptions = () => {
        setLoading(true)
        doRequest(api.cars.list(), pushNotify, logout).ready.then(data => {
            setOptions(data.cars.map((car) => ({ key: car.id, value: car.id, text: car.model.length ? `${car.regPlate} (${car.model})` : car.regPlate })))
        }).catch(() => {})
        .finally(() => setLoading(false))
    }

    const onSetRfidHandler = () => {
        if (!car || !rfid) return;

        setSaving(true)
        doRequest(api.cars.edit(car, { rfid }), pushNotify, logout).ready.then(() => {
            pushNotify('success', 'title.success', 'msg.rfidRegisterSuccess')
        }).catch(() => {}).finally(() => {
            setSaving(false)
        })
    }

    useEffect(() => {
        getOptions()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="carPage">
            <Dropdown
                clearable
                search
                fluid
                selection
                placeholder={translates.selectCar}
                disabled={loading || saving}
                options={options}
                value={car}
                lazyLoad
                onChange={(e, { value }) => setCar(value)}
                noResultsMessage={translates.carNotFound}
            />
            <div className="carEditForm">
                <Input disabled={loading || saving || !car} placeholder={translates.rfid} value={rfid} onChange={(e, { value }) => setRfid(value)} onClick={() => setRfid('')}/>
                <Button disabled={ !car || !rfid || loading || saving } loading={saving} onClick={onSetRfidHandler}>{translates.setRfid}</Button>
            </div>
        </div>
    )
}

export default injectIntl(CarPage)