import React from 'react'
import './Label.css'

export default function Label(props) {
    const { text } = props

    return (
        <div className="label-block">
            {text}
        </div>
    )
}
