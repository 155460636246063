import React, {useState} from 'react'
import { Dropdown } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'

import ModalWindow from 'components/ModalWindow'

function UserSelectModal (props) {
    const { userList, onAccept, loading, onCancel, intl } = props
    const [user, setUser] = useState('')
    const translates = {
      btnCancel: intl.formatMessage({ id: 'btn.cancel' }),
      btnNext: intl.formatMessage({ id: 'btn.next' }),
      title: intl.formatMessage({ id: 'title.selectUserTitle' }),
      placeholder: intl.formatMessage({ id: 'title.selectUser' }),
    }

    const onAcceptAction = onAccept || (() => console.error('onAccept not defined'))
    const onCancelAction = onCancel || (() => console.error('onCancel not defined'))

    const modalActions = [
      { title: translates.btnCancel, disabled: false, loading: false, onClick: onCancelAction, color: 'default' },
      { title: translates.btnNext, disabled: Boolean(!user), loading: loading, onClick: () => onAcceptAction(user), color: 'green' }
    ]

    const options = userList?.map(item => ({ text: item.lastName, value: item.id }))

    return (
        <ModalWindow title={translates.title} open={Boolean(userList?.length)} iconType="info" actions={modalActions}>
          <Dropdown 
            options = {options} 
            placeholder={translates.placeholder}
            fluid
            selection 
            value={user}
            onChange={(e, target) => setUser(target.value)}
          />
        </ModalWindow>
    )
}
/*
UserSelectModal.propTypes = {
    intl: PropTypes.object,
    userList: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        firstName: PropTypes.string,
        lastName: propTypes.string
    })),
    onAccept: PropTypes.func
}
*/
export default injectIntl(UserSelectModal)