export default function doRequest (api, pushNotify, logout) {
    return {
        ready: api.ready.then(res => {
                if (res.status === 200) {
                    return res.data
                } else {
                    if (res.status === 401 && typeof logout === 'function') logout()
                    throw String(res.data.text)
                }
            }).catch(e => {
                const message = 'msg.' + (typeof e === 'string' ? e : 'conectionError')
                typeof pushNotify === 'function' && pushNotify('error', 'title.error', message, 2000)
                throw 'Error: ' + message // eslint-disable-line
            }),
        controller: api.controller
    }
}