import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Button } from 'semantic-ui-react'

import { SettingsContext } from 'context'

import './ThemeSelector.css'

function ThemeSelector (props) {
  const { intl } = props
  const { theme, setTheme, themeList } = useContext(SettingsContext)

  const showThemes = () => {
    const buttons = []

    for (const element of themeList || []) {
      buttons.push(
        <Button 
          key={element}
          disabled={element === theme} 
          name={element}  
          onClick={e => {setTheme(e.target.name)}}
        >
          {intl.formatMessage({ id: `theme.${element}` })}
        </Button>)
    }

    return buttons
  }

  return (
    <Button.Group>
      {showThemes()}
    </Button.Group>
  )
}

ThemeSelector.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(ThemeSelector)
