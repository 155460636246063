import React, { useContext, useState, useEffect, useMemo } from 'react'

import { injectIntl } from 'react-intl'
import { UserContext, PdaContext, NotifyContext } from 'context'
import { Dropdown, Table, TableBody, Loader } from 'semantic-ui-react'
import { Card, CardBody, CardIcon } from '../Card'
import Label from '../Label'
import Paginator from '../Paginator'
import Filter from '../Filter'
import api from 'actions/api'
import doRequest from 'actions/doRequest'

import './ResiduePage.css'

import { cardsOnPage, orderRolesAccess, fields } from '../../../constants/config.json'
const show = fields?.leftovers || {}

function ResiduePage(props) {
    const { intl } = props
    const { currentUser, logout } = useContext(UserContext)
    const { pushNotify } = useContext(NotifyContext)
    const { isPda } = useContext(PdaContext)
    const [loading, setLoading] = useState(true)
    const [residueList, setResidueList] = useState([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)
    const [sortBy, setSortBy] = useState('bySmaller')
    const [filter, setFilter] = useState({})
    const [nomenclatures, setNomenclatures] = useState([])
    const [elevators, setElevators] = useState([])

    const translates = {
        elevator: intl.formatMessage({ id: "title.elevator" }),
        counterparty: intl.formatMessage({ id: "title.counterparty" }),
        product: intl.formatMessage({ id: "title.product" }),
        quantity: intl.formatMessage({ id: "title.quantity" }),
        netWeight: intl.formatMessage({ id: "title.netWeight" }),
        bySmaller: intl.formatMessage({ id: "sort.bySmaller" }),
        byBigger: intl.formatMessage({ id: "sort.byBigger" }),
        byElevator: intl.formatMessage({ id: "sort.byElevator" }),
        byCropType: intl.formatMessage({ id: "sort.byCropType" }),
        notFound: intl.formatMessage({ id: 'title.recordNotFound' })
    }

    const filterList = useMemo(() => {
        let ret = []
        show.elevator && ret.push(
            {
                title: translates.elevator,
                type: 'dropdown',
                name: 'elevator',
                options: elevators.map(elevator => ({
                    key: elevator.id,
                    text: elevator.name,
                    value: elevator.id
                }))
            },
        )
        show.nomenclature && ret.push(
            {
                title: translates.product,
                type: 'dropdown',
                name: 'croptype',
                options: nomenclatures.map(nomenclature => ({
                    key: nomenclature.id,
                    text: nomenclature.name,
                    value: nomenclature.id
                }))
            }
        )
        return ret
        }, [intl.locale, nomenclatures, elevators]) // eslint-disable-line react-hooks/exhaustive-deps

    const sortOptions = useMemo(() => {
        let ret = []
        ret.push({ key: 1, text: translates.bySmaller, value: 'bySmaller' })
        ret.push({ key: 2, text: translates.byBigger, value: 'byBigger' })
        show.elevator && ret.push({ key: 3, text: translates.byElevator, value: 'byElevator' })
        show.nomenclature && ret.push({ key: 4, text: translates.byCropType, value: 'byCropType' })

        return ret
    }, [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadResidues = (page) => {
        setResidueList([])
        setLoading(true)

        const offset = cardsOnPage * (page - 1), count = cardsOnPage

        const req = api.residue.list(currentUser.id, offset, count, filter.elevator, filter.croptype, sortBy)


        doRequest(req, pushNotify, logout).ready.then(data => {
            const { residues, count } = data
            const pages = Math.ceil(count / cardsOnPage)

            setResidueList(residues)                
            setLoading(false)

            if (page > pages && pages > 0) {
                loadResidues(pages)
            } else {
                setPageCount(pages)
                setPage(page)
            }
        })
        .catch(console.error)
        .finally(() => {
            setLoading(false)
        })
    }

    useEffect(() => {
        let requests = []
        requests.push(doRequest(api.nomenclatures.list(), pushNotify, logout).ready)
        requests.push(doRequest(api.elevators.list(), pushNotify, logout).ready)

        Promise.all(requests).then(result => {
            result.forEach(data => {
                if (data.elevators) setElevators(data.elevators);
                if (data.nomenclatures) setNomenclatures(data.nomenclatures);
            }, {})
            
            loadResidues(1)
        }).catch(console.error)
        .finally(() => setLoading(false))
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (loading) return
        loadResidues(1)
    }, [sortBy, filter])  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            <div className="topPanel">
                <Filter filter={filter} disabled={loading} setFilter={e => { setFilter(e) }} filterList={filterList}/>
                <Dropdown options={sortOptions} disabled={loading} selection icon="sort" value={sortBy} onChange={(e, { value }) => setSortBy(value)}/>
            </div>
            { loading ? 
                <Loader className={"page-loader"} active inline="centered" />
            :
                residueList.length === 0 ?
                    <Label text={translates.notFound} />
                :
                    isPda ?
                        residueList.map((residue) => (
                            <Card key={residue.id} className="infoCard">
                                <CardIcon name="industry" />
                                <CardBody>
                                    { show?.counterparty && orderRolesAccess.includes(currentUser.role) ? <span><b>{translates.counterparty}:</b> {residue.counterparty || '---'}</span> : '' }
                                    { show?.elevator ? <span><b>{translates.elevator}:</b> {residue.elevator || '---'}</span> : '' }
                                    { show?.nomenclature ? <span><b>{translates.product}:</b> {residue.cropType || '---'}</span> : '' }
                                    { show?.quantity ? <span><b>{translates.quantity}:</b> {residue.quantity.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {residue.units}</span> : '' }
                                    { show?.netWeight ? <span><b>{translates.netWeight}:</b> {residue.netWeight.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {residue.units}</span> : '' }
                                </CardBody>
                            </Card>
                        ))
                    :
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    { show?.counterparty && orderRolesAccess.includes(currentUser.role) ? <Table.HeaderCell>{translates.counterparty}</Table.HeaderCell> : null }
                                    { show?.elevator ? <Table.HeaderCell>{translates.elevator}</Table.HeaderCell> : null }
                                    { show?.nomenclature ? <Table.HeaderCell>{translates.product}</Table.HeaderCell> : null }
                                    { show?.quantity ? <Table.HeaderCell>{translates.quantity}</Table.HeaderCell> : null }
                                    { show?.netWeight ? <Table.HeaderCell>{translates.netWeight}</Table.HeaderCell> : null }
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {
                                    residueList.map((residue) => (
                                        <Table.Row key={residue.id}>
                                            { show?.counterparty && orderRolesAccess.includes(currentUser.role) ? <Table.Cell>{residue.counterparty || '---'}</Table.Cell> : null } 
                                            { show?.elevator ? <Table.Cell>{residue.elevator || '---'}</Table.Cell> : null }
                                            { show?.nomenclature ? <Table.Cell>{residue.cropType || '---'}</Table.Cell> : null }
                                            { show?.quantity ? <Table.Cell>{residue.quantity.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {residue.units}</Table.Cell> : null }
                                            { show?.netWeight ? <Table.Cell>{residue.netWeight.replace(/\B(?=(\d{3})+(?!\d))/g, " ")} {residue.units}</Table.Cell> : null }
                                        </Table.Row>
                                    ))
                                }
                            </TableBody>
                        </Table>
            }
            <Paginator pageCount={pageCount} page={page} setPage={loadResidues} disabled={loading} />
        </React.Fragment>
    )
}

export default injectIntl(ResiduePage)
