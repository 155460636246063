import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import MessageIcon from '../../MessageIcon'
import { Icon } from 'semantic-ui-react'

import './Notify.css'

function Notify (props) {
  const { notify, intl, deleteNotifyAction } = props
  const timerRef = useRef(null)
  const [appearance, setAppearance] = useState(true)

  const translates = {
    title: intl.formatMessage({ id: notify.title }),
    content: intl.formatMessage({ id: notify.content })
  }

  useEffect(() => {
    if (appearance) { 
      timerRef.current = setTimeout(() => { setAppearance(false) }, 100)
    } else if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
    }
  }, [appearance])
  
  return (
    <div className={'notify' + (notify.deleting || appearance ? ' deleting' : '')}>
      <div className={'notifyBody' + (notify.deleting || appearance ? ' deleting' : '')}>
        <span className="notifyTitle"><MessageIcon type={notify.type}/>{translates.title || notify.title}<Icon onClick={() => deleteNotifyAction(notify.id)} name="close"/></span>
        <span className="notifyContent">{translates.content || notify.content}</span>
      </div>
    </div>
  )
}

Notify.propTypes = {
  notify: PropTypes.object,
  intl: PropTypes.object,
  deleteNotifyAction: PropTypes.func
}

export default injectIntl(Notify)
