import React from 'react'
import { Button } from 'semantic-ui-react'

import './Paginator.css'

const pageRange = 1

function Paginator (props) {
    const { page, setPage, pageCount, disabled } = props

    const onPageChange = (e, { value }) => {
        setPage(value)
    }

    const getButtons = () => {
        // Перша сторінка
        let pages = [<Button key={1} disabled={page === 1 || disabled} value={1} onClick={onPageChange}>1</Button>]

        // Якщо обрана сторінка надто далеко від першої
        if (page > 2 + pageRange) pages.push(<span key='sb'>…</span>)

        // Показуємо обрану та сусідні сторінки
        for (let i = page - pageRange; i <= page + pageRange; i++)
            if (i > 1 && i < pageCount)
                pages.push(<Button key={i} disabled={page === i || disabled} value={i} onClick={onPageChange}>{i}</Button>)

        // Якщо обрана сторінка надто далекуо від останньої
        if (page < pageCount - pageRange - 1) pages.push(<span key='sa'>…</span>)

        // Остання сторінка
        pages.push(<Button key={pageCount} disabled={page === pageCount || disabled} value={pageCount} onClick={onPageChange}>{pageCount}</Button>)

        return pages
    }

    if (pageCount <= 1)
        return []

    return (
        <div className="paginator">
            {getButtons()}
        </div>
    )
}

export default Paginator
