import React, { useContext, useEffect, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'
import { Card, CardIcon, CardBody, CardActions } from '../Card'
import { Loader } from 'semantic-ui-react'
import Paginator from '../Paginator'
import DriverEditModal from './DriverEditModal'

import api from '../../../actions/api'
import doRequest from 'actions/doRequest'

import { cardsOnPage } from 'constants/config.json'
import './DriversPage.css'

function DriversPage (props) {
    const { intl } = props
    const { logout } = useContext(UserContext)
    const { pushNotify } = useContext(NotifyContext)

    const [loading, setLoading] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(1)

    const translates = useMemo(() => {
        return {
            driver: intl.formatMessage({ id: 'title.driver' }),
            status: intl.formatMessage({ id: 'title.status' }),
            active: intl.formatMessage({ id: 'user.active' }),
            disabled: intl.formatMessage({ id: 'user.disable' })
        }
    }, [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadDrivers = (page) => {
        setDrivers([])
        setLoading(true)

        const offset = cardsOnPage * (page - 1), count = cardsOnPage
        const req = doRequest(api.driver.list(offset, count), pushNotify, logout)

        req.ready.then(({ drivers, count } ) => {
            const pages = Math.ceil(count / cardsOnPage)

            setDrivers(drivers)      
            
            if (page > pages & pages > 0) {
                loadDrivers(pages)
            } else {
                setPageCount(pages)
                setPage(page)
            }
        }).catch(console.error)
        .finally(() => {
            setLoading(false)
        })
    }

    const refresh = () => {
        loadDrivers(page)
    }

    useEffect(() => refresh(), []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            { loading ? 
                <Loader className={"page-loader"} active inline="centered" />
            :
                <React.Fragment>
                    <DriverEditModal type="add" onEditUsers={refresh} />
                    {drivers.map((driver) => (
                        <Card key={driver.id} className="infoCard">
                            <CardIcon name="user circle" />
                            <CardBody>
                                <span><b>{translates.driver}:</b> {driver.firstName} {driver.lastName}</span>
                                <span><b>{translates.status}:</b> {driver.active ? translates.active : translates.disabled}</span>
                            </CardBody>
                            <CardActions>
                                <DriverEditModal id={driver.id} type="edit" onEditUsers={refresh} />    
                            </CardActions>
                        </Card>
                    ))}
                </React.Fragment>
            }
            <Paginator pageCount={pageCount} page={page} setPage={loadDrivers} disabled={loading} />
        </React.Fragment>
    )
}

export default injectIntl(DriversPage)
