import config from '../constants/config.json'

function httpRequest(method, url, body = undefined) {
    const controller = new AbortController()
    const signal = controller.signal

    const request = fetch(config.api.url + url, {
        method: method,
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: "include",
        body: JSON.stringify(body),
        signal
    }).then(response => {
        return response.json().then(data => {
            return {
                data: data,
                status: response.status 
            }
        }).catch(() => { 
            throw 'uncorrectResponse' // eslint-disable-line
        })
    })

    return {
        controller: controller,
        ready: request
    }
}
  
const api = {
    authorization: {
        generateAuthPin: (credentials) => httpRequest('POST', '/api/v1/getpin', credentials),
        login: (creditionals) => httpRequest('POST', '/api/v1/auth', creditionals),
        loguot: () => httpRequest('HEAD', '/api/v1/logout')
    },
    user: {
        list: (offset, count) => httpRequest('GET', `/api/v1/user?offset=${offset}&count=${count}`),
        user: (id) => httpRequest('GET', `/api/v1/user/${id}`),
        edit: (user) => httpRequest('PATCH', `/api/v1/user/${user?.id}`, user),
        delete: (id) => httpRequest('DELETE', `/api/v1/user/${id}`),
        addUser: (user) => httpRequest('PUT', '/api/v1/user', user)
    },
    departure: {
        list: (offset, count, filDateOrderFrom, filDateOrderTo, filElevator, filCar, filDriver, filType, filStep, filWaybill, sort) => {
            let params = {}
            if (offset !== undefined) params.offset = offset
            if (count !== undefined) params.count = count
            if (filDateOrderFrom !== undefined) params.filDateOrderFrom = filDateOrderFrom
            if (filDateOrderTo !== undefined) params.filDateOrderTo = filDateOrderTo
            if (filElevator !== undefined) params.filElevator = filElevator
            if (filCar !== undefined) params.filCar = filCar
            if (filDriver !== undefined) params.filDriver = filDriver
            if (filType !== undefined) params.filType = filType
            if (filStep !== undefined) params.filStep = filStep
            if (filWaybill !== undefined) params.filWaybill = filWaybill
            if (sort !== undefined) params.sort = sort

            return httpRequest('GET', `/api/v1/departure?${new URLSearchParams(params).toString()}`)
        },
        departure: (id) => httpRequest('GET', `/api/v1/departure/${id}`)
    },
    residue: {
        list: (customer, offset, count, filElevator, filCropType, sort) => {
            let params = {}
            if (customer !== undefined) params.customer = customer
            if (offset !== undefined) params.offset = offset
            if (count !== undefined) params.count = count
            if (filElevator !== undefined) params.filElevator = filElevator
            if (filCropType !== undefined) params.filCropType = filCropType
            if (sort !== undefined) params.sort = sort
            
            return httpRequest('GET', `/api/v1/residue?${new URLSearchParams(params).toString()}`)
        }
    },
    elevators: {
        list: () => httpRequest('GET', `/api/v1/elevator`)
    },
    nomenclatures: {
        list: () => httpRequest('GET', `/api/v1/nomenclature`)
    },
    driver: {
        list: (offset, count) => {
            let params = {}
            if (offset !== undefined) params.offset = offset
            if (count !== undefined) params.count = count
            return httpRequest('GET', `/api/v1/driver?${new URLSearchParams(params).toString()}`)
        },
        driver: (id) => httpRequest('GET', `/api/v1/driver/${id}`),
        edit: (driver) => httpRequest('PATCH', `/api/v1/driver/${driver.id}`, driver),
        add: (driver) => httpRequest('PUT', `/api/v1/driver`, driver)
    },
    cars: {
        list: () => httpRequest('GET', `/api/v1/car`),
        edit: (id, car) => httpRequest('PATCH', `/api/v1/car/${id}`, car)
    },
    queue: {
        list: (offset, count) => httpRequest('GET', `/api/v1/queue?offset=${offset}&count=${count}`),
        delete: (id) => httpRequest('DELETE', `/api/v1/queue/${id}`)
    },
    request: {
        send: (values) => httpRequest('POST', `/api/v1/request`, values)
    },
    crash: {
        list: () => httpRequest('GET', '/api/v1/crash'),
        confirm: (id, options) => httpRequest('POST', `/api/v1/crash/${id}`, options)
    }
}

export default api
