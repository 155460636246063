import React, { useState, useEffect, useRef } from 'react'
import { getUserLocale } from 'get-user-locale'
import { IntlProvider } from 'react-intl'
import { BrowserRouter, Switch } from 'react-router-dom'

import { UserContext, NotifyContext, PdaContext, SettingsContext } from 'context'

import api from '../../actions/api'

import UserRoute from '../UserRoute'
import TopPanel from '../TopPanel'
import MainPage from '../MainPage'
import LoginPage from '../LoginPage'
import CrashModal from '../CrashModal'

import './App.css'
import translates from '../../constants/translates.json'
import localeList from 'constants/localeList.json'
import themeList from 'constants/themeList.json'
import { companyName, crashModalAccess } from 'constants/config.json'
import NotifyBlock from '../NotifyBlock'

const defaultNotifys = {
  params: {
    id: 2
  },
  notifyList: []
}

function getLocale () {
  const loc = localStorage.getItem('locale') || getUserLocale().split('-')[0]

  return translates[loc] ? loc : 'en'
}

function getTheme () {
  const theme = localStorage.getItem('theme')

  return themeList.includes(theme) ? theme : themeList[0]
}

document.body.classList.add(getTheme())

function App () {
  const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('user')))
  const [locale, setLocaleState] = useState(getLocale())
  const [theme, setThemeState] = useState(getTheme())
  const [notify, setNotify] = useState(defaultNotifys)
  const [isPda, setPda] = useState(false)
  const pdaRef = useRef(isPda)
 
  const setUser = (user) => {
    setCurrentUser(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const logout = () => {
    api.authorization.loguot()
    setUser({})
  }

  const setLocale = (newLocale) => {
    localStorage.setItem('locale', newLocale)
    setLocaleState(newLocale)
  }

  const setTheme = (newTheme) => {
    if (newTheme !== theme) {
      if (theme) 
        document.body.classList.remove(theme)

      document.body.classList.add(newTheme)
      setThemeState(newTheme)
      localStorage.setItem('theme', newTheme)
    }
  }

  const finnalyDelete = (id) => {
    notify.notifyList.splice(notify.notifyList.findIndex((n) => n.id === id), 1)
    setNotify({ ...notify })
  }

  const deleteNotify = (id) => {
    if (notify) {
      const n = notify.notifyList.find(n => n.id === id)
      if (n && !n.deleting) {
        n.deleting = true
        setNotify({ ...notify })

        setTimeout(() => { finnalyDelete(id) }, 400)
      }
    } else {
      console.error('Notify is undefined')
    }
  }

  const pushNotify = (type, title, content, timeout = 4000) => {
    if (notify && !notify.notifyList.filter(item => title === item.title && content === item.content).length) {
      const id = ++notify.params.id
      notify.notifyList.push({ id, type, title, content, deleting: false })
      setNotify({ ...notify })

      if (timeout > 0) setTimeout(() => { deleteNotify(id) }, timeout)
    } else {
      console.error('Notify is undefined')
    }
  }

  // Розмір екрану
  useEffect(() => {
    pdaRef.current = isPda
  }, [isPda])

  useEffect(() => {
    document.title = translates[locale]['title.htmlTitle'] + ' — ' + companyName
  }, [locale])

  const checkWidth = () => {
    const { matches } = window.matchMedia(`(max-width: 768px)`);
    setPda(matches)
  }

  useEffect(() => {
    checkWidth(null)
    window.addEventListener("resize", checkWidth);

    return () => {
        window.removeEventListener("resize", checkWidth);
    }
  }, [])

  return (
    <BrowserRouter>
      <PdaContext.Provider value={{ isPda }}>
        <IntlProvider locale={locale} messages={ translates[locale]} onError={() => false}>
          <NotifyContext.Provider value={{pushNotify}}>
            <SettingsContext.Provider value={{ locale, setLocale, localeList, theme, setTheme, themeList}}>
              <UserContext.Provider value={{currentUser, setUser, logout}}>
                <UserRoute path="/" component={TopPanel} />
                <div className='appBody'>
                  <Switch>
                    <UserRoute exact path="/login" component={LoginPage} pushNotify={pushNotify}/>
                    <UserRoute path="/" component={MainPage} />
                  </Switch>
                </div>
                { crashModalAccess.includes(currentUser?.role) ? <CrashModal /> : null }
                <NotifyBlock notifyes={notify.notifyList} deleteNotifyAction={deleteNotify} />
              </UserContext.Provider>
            </SettingsContext.Provider>
          </NotifyContext.Provider>
        </IntlProvider>
      </PdaContext.Provider>
    </BrowserRouter>
  )
}

export default App
