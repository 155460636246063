import React, {useContext, useState} from 'react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'
import ModalWindow from '../../../ModalWindow'
import reactStringReplace from 'react-string-replace';
import { Button, Icon } from 'semantic-ui-react';

import api from 'actions/api'

import './DeleteModal.css'
import doRequest from 'actions/doRequest';

function DeleteModal (props) {
    const { queue, onDelete, disabled, intl } = props
    const logout = useContext(UserContext)

    const translates = {
        deleteTitle: intl.formatMessage({ id: 'title.deleting' }),
        message: intl.formatMessage({ id: 'title.deleteFromQueue' }),
        cancel: intl.formatMessage({ id: 'btn.cancel' }),
        delete: intl.formatMessage({ id: 'btn.delete' }),
    }

    const [open, setOpen] = useState(false)
    const [deleting, setDeleting] = useState(false)
    const { pushNotify } = useContext(NotifyContext)

    const deleteDriver = () => {
        if (!deleting) {
            setDeleting(true)

            const request = doRequest(api.queue.delete(queue.id), pushNotify, logout)
            request.ready.then(res => {
                pushNotify('info', 'title.success', 'msg.deleteFromQueueSuccess')
                onDelete()
            }).catch(console.error)
            .finally(() => {
                setDeleting(false)
            })
        }
    }

    const getButtons = () => [
        { title: translates.cancel, onClick: () => setOpen(false), loading: false },
        { title: translates.delete, onClick: deleteDriver, color: 'red', loading: deleting }
    ]
    return (
        <ModalWindow 
            iconType="warning"
            open={open && !disabled}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            title={translates.deleteTitle} 
            trigger={
                <Button disabled={disabled} className="deleteButton" icon><Icon name="delete"/><span>{translates.delete}</span></Button> 
            }
            actions={getButtons()}
        >
            {reactStringReplace(translates.message, '%s', (match, i) => (<b key={i}>{queue.driver}</b>))}
        </ModalWindow>
    )
}

export default injectIntl(DeleteModal)
