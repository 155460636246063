import React from 'react'
import { injectIntl } from 'react-intl'
import { Dropdown } from 'semantic-ui-react'

function DropDownPicker(props) {
    const { value, options, onChange, className, intl } = props
    const translates = {
        noResult: intl.formatMessage({ id: 'title.resultNotFound' })
    }

    return (
        <Dropdown
            className={className}
            fluid
            multiple
            lazyLoad
            onChange={(e, { sq, value }) => onChange(value)}
            options={options}
            search
            selection
            value={value || []}
            noResultsMessage = {translates.noResult}
        />
    )
}

export default injectIntl(DropDownPicker)
