import React, { useCallback, useContext, useMemo, useState } from 'react'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'
import { Form, Input, Dropdown, Button, Icon, Checkbox, Loader } from 'semantic-ui-react'
import ModalWindow from '../../../ModalWindow'
import api from '../../../../actions/api'

import './EditModal.css'
import { PdaContext } from 'context'


import { roles, editableRoles } from 'constants/config.json'
import doRequest from 'actions/doRequest'

const emptyUserEntity = {
    login: '',
    pass: '',
    firstName: '',
    lastName: '',
    role: 0,
    botAuth: false,
    active: true,
    phones: []
}
const phoneValidator = /^0[0-9]{0,9}/
const phoneCheck = /^0[0-9]{9}/

function EditModal(props) {
    const { type, id, intl, onEditUsers, hide } = props
    const { logout } = useContext(UserContext)
    const { isPda } = useContext(PdaContext)

    const translates = useMemo(() => {
        return ({
            editUserTitle: intl.formatMessage({ id: 'title.editUser' }),
            addUserTitle: intl.formatMessage({ id: 'btn.addUser' }),
            loginInput: intl.formatMessage({ id: 'user.login' }),
            passInput: intl.formatMessage({ id: 'user.password' }),
            firstNameInput: intl.formatMessage({ id: 'user.firstName' }),
            lastNameInput: intl.formatMessage({ id: 'user.lastName' }),
            phoneNumber: intl.formatMessage({ id: 'user.phoneNumber' }),
            onBotAuth: intl.formatMessage({ id: 'user.botAuth' }),
            activeUser: intl.formatMessage({ id: 'user.active' }),
            roleInput: intl.formatMessage({ id: 'user.role' }),
            btnCancel: intl.formatMessage({ id: 'btn.cancel' }),
            btnSave: intl.formatMessage({ id: 'btn.save' }),
            btnAdd: intl.formatMessage({ id: 'btn.add' }),
            btnEdit: intl.formatMessage({ id: 'btn.edit' })
        })
    }, [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    const [ open, setOpen] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ saving, setSaving ] = useState(false)
    const [ user, setUser ] = useState(emptyUserEntity)

    const { pushNotify } = useContext(NotifyContext)

    const editable = user.role ? editableRoles.includes(Number(user.role)) : true

    const getRoleList = useCallback(() =>
        roles.map(role => { 
            return {
                key: role, 
                disabled: !editableRoles.includes(Number(role)), 
                value: role, 
                text: intl.formatMessage({ id: `user.role.${role}`})
            }
        }), 
        [intl.locale] // eslint-disable-line react-hooks/exhaustive-deps
    )

    const onSaveUser = () => {
        if (!saving) {
            if (user.phones?.filter(phone => phone.match(phoneCheck) === null).length) {
                pushNotify('error', 'title.error', 'msg.phoneNumberWrong')
                return
            }

            setSaving(true)

            const request = doRequest((
                type === 'edit' ? 
                    api.user.edit({ ...user, id: id, pass: user.pass || undefined }) 
                : 
                    api.user.addUser(user)
            ), pushNotify, logout)

            request.ready.then(data => {
                if (type === 'edit') 
                    pushNotify('info', 'title.success', 'msg.editUserSuccess')
                else
                    pushNotify('info', 'title.success', 'msg.addUserSuccess')

                setOpen(false)
                onEditUsers(data)
            }).catch(console.error)
            .finally(() => {
                setSaving(false)
            })
        }
    }

    const getButtons = () => [
        { title: translates.btnCancel, onClick: onClose, disabled: saving || loading, loading: false },
        { title: type === 'edit' ? translates.btnSave : translates.btnAdd, onClick: onSaveUser, color: 'green', disabled: saving || loading, loading: saving }
    ]

    const openHandler = () => {
        if (id !== undefined) {
            setLoading(true)
            const request = api.user.user(id)

            request.ready.then(res => {
                if (res.status === 200) {
                    const user = res.data;
                    setUser({ ...user, pass: '' })
                } else if (res.status === 401) {
                    logout()
                } else {
                    pushNotify('error', 'userspage.setUserErrorTitle', ('server.' + res.data.text))
                }
            }).catch(e => {
                pushNotify('error', 'server.errorTitle', 'server.conectionError')
            }).finally(() => {
                setLoading(false)
            })
        }
        setOpen(true)
    }

    const onPhoneChangeHandler = (id, data) => {
        /// Валідація поля введення 
        const [valid] = data.match(phoneValidator) || ['0']
        user.phones[id] = valid

        setUser({ ...user })
    }

    const onPhoneDeleteHandler = id => {
        setUser({ ...user, phones: user.phones.filter((e, index) => index !== id) })
    }

    const onPhoneCreateHandler = () => {
        if (!user.phones) user.phones = []
        
        if (!user.phones.includes('')) {
            user.phones.push('')
            setUser({ ...user })
        }
    }

    const onClose = () => {
        if (!saving) { 
            setOpen(false) 
            setUser({ ...emptyUserEntity, phones: [] })
        }
    }
    
    return (
        <ModalWindow 
            open={open}
            onClose={onClose}
            onOpen={openHandler}
            title={type === 'edit' ? translates.editUserTitle : translates.addUserTitle} 
            iconType={type === 'edit' ? 'edit' : 'plus'}
            trigger={
                hide ? '' : <Button className="editButton" icon><Icon name={type === 'edit' ? 'edit' : 'plus'}/><span>{type === 'edit' ? translates.btnEdit : translates.addUserTitle}</span></Button> 
            }
            scrolling={!loading && isPda}
            actions={getButtons()}
        >
            { loading ? 
                <Loader className={"page-loader"} active inline="centered" />
            :
                <Form className="userForm">
                    <span className="userFormRow">
                        {translates.loginInput}: 
                        <Input 
                            className="editField" 
                            disabled={saving || loading} 
                            placeholder={translates.loginInput} 
                            value={user.login}            
                            onChange={e => setUser({ ...user, login: e.target.value })}
                        />
                    </span>
                    <span className="userFormRow">
                        {translates.passInput}: 
                        <Input 
                            className="editField" 
                            disabled={saving || loading} 
                            type="password" 
                            placeholder={translates.passInput} 
                            value={user.pass || ''} 
                            onChange={e => setUser({ ...user, pass: e.target.value })}
                        />
                    </span>
                    <span className="userFormRow">
                        {translates.firstNameInput}: 
                        <Input 
                            className="editField" 
                            disabled={saving || loading || !editable} 
                            placeholder={translates.firstNameInput} 
                            value={user.firstName} 
                            onChange={e => setUser({ ...user, firstName: e.target.value })}
                        />
                    </span>
                    <span className="userFormRow">
                        {translates.lastNameInput}: 
                        <Input 
                            className="editField" 
                            disabled={saving || loading || !editable} 
                            placeholder={translates.lastNameInput} 
                            value={user.lastName} 
                            onChange={e => setUser({ ...user, lastName: e.target.value })}
                        />
                    </span>
                    <span className="userFormRow">
                        {translates.phoneNumber}: 
                        <div className="phones">
                            {user.phones?.map((phone, index) => { 
                                return (
                                    <div key={index} className="phoneField">
                                        <Input 
                                            className="editField" 
                                            disabled={saving || loading || !editable} 
                                            placeholder={translates.phoneNumber} 
                                            value={phone} 
                                            error={phone.match(phoneCheck) ? false : true}
                                            onChange={e => onPhoneChangeHandler(index, e.target.value)}
                                        />
                                        <Button className="deletePhone" disabled={saving || loading || !editable} icon><Icon name="trash alternate" onClick={() => onPhoneDeleteHandler(index)}/></Button>
                                    </div>
                                )
                            })}
                            <Button icon onClick={onPhoneCreateHandler} disabled={saving || loading || !editable}><Icon name="plus"/></Button>
                        </div>
                    </span>
                    <span className="userFormRow">
                        {translates.roleInput}: 
                        <Dropdown 
                            className="selectField" 
                            disabled={saving || loading || !editable} 
                            options={getRoleList()} 
                            value={user.role} 
                            onChange={(e, { value }) => setUser({ ...user, role: value })} 
                            search 
                            selection
                        />
                    </span>
                    <span className="userFormRow">
                        <Checkbox 
                            className="editField" 
                            disabled={saving || loading} 
                            checked={Boolean(user.botAuth)} 
                            label={translates.onBotAuth}
                            onChange={(e, { checked }) => setUser({ ...user, botAuth: checked })} 
                        />
                    </span>
                    <span className="userFormRow">
                        <Checkbox 
                            className="editField" 
                            disabled={saving || loading} 
                            checked={Boolean(user.active)} 
                            label={translates.activeUser}
                            onChange={(e, { checked }) => setUser({ ...user, active: checked })} 
                        />
                    </span>
                </Form>
            }
        </ModalWindow>
    )
}

export default injectIntl(EditModal)