import React, { useContext } from 'react'
import ThemeSelector from '../ThemeSelector'
import LangSelector from '../LangSelector'
import { Image } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import { UserContext, PdaContext } from 'context'

import './TopPanel.css'
import LogoImg from '../../assets/img/logo.png'
import { Button } from 'semantic-ui-react'

function TopPanel (props) {
  const { intl } = props
  const { currentUser, logout } = useContext(UserContext)
  const { isPda } = useContext(PdaContext)

  const translates = {
    role: ( currentUser?.role !== undefined ? intl.formatMessage({ id: 'user.role.' + currentUser.role }) : '' )
  }

  if (currentUser?.auth) {
    return (
      <header>
        <div className="leftBlock">
          <Image src={LogoImg} alt="" size={ isPda ? 'tiny' : 'mini'}/>
        </div>
        <div className="userBlock">
          <span className="userName">
            { currentUser.role !== 3 ?
                `${currentUser.firstName} ${currentUser.lastName}`
              :
                currentUser.firstName
            }
          </span>
          <span>{translates.role}</span>
        </div>
        <div className="rightBlock">
          <Button onClick={logout} icon="log out"/>
        </div>
      </header>
    )
  }

  return (
    <header className="loginPage">
      <div className="leftBlock">
        <ThemeSelector />
      </div>
      <div className="userBlock"/>
      <div className="rightBlock">
        <LangSelector langMessages={props.langMessages} setLocale={props.setLocale} />
      </div>
    </header>
  )
}

export default injectIntl(TopPanel)
