import React, { useMemo, useState, useEffect, useRef, useCallback } from 'react'
import { Table, TableBody, Button, Icon, Popup } from 'semantic-ui-react'
import { injectIntl } from 'react-intl'
import ModalWindow from '../ModalWindow'

import api from 'actions/api'
import doRequest from 'actions/doRequest'

import './CrashModal.css'

function CrashModal(props) {
    const { intl } = props

    const [crashList, setCrashList] = useState([])
    const [blocked, setBlocked] = useState([])
    const crashListRef = useRef(crashList)
    const blockedRef = useRef(blocked)

    const translates = useMemo(() => ({
        crashTitle: intl.formatMessage({ id: 'title.crash' }),
        date: intl.formatMessage({ id: 'title.date' }),
        car: intl.formatMessage({ id: 'title.car' }),
        reason: intl.formatMessage({ id: 'title.reason' }),
        btnCancel: intl.formatMessage({ id: 'btn.cancel' }),
        btnResolve: intl.formatMessage({ id: 'btn.resolve' }),
        btnFinishHim: intl.formatMessage({ id: 'btn.finish' }),
        btnAccept: intl.formatMessage({ id: 'btn.ok' }),
    }), [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        crashListRef.current = crashList
    }, [crashList])

    useEffect(() => {
        blockedRef.current = blocked
    }, [blocked])

    useEffect(() => {
        let request = null

        const interval = setInterval(() => {
            if (request?.controller && !request.controller.aborted) {
                request.controller.abort()
                return
            }

            request = doRequest(api.crash.list())
            request.ready.then(data => {
                if (data.length !== 0 || crashListRef.current.length !== data.length) {
                    setCrashList(data)
                }
            })
            .catch(e => console.error(e, 'Crash request error'))
            .finally(() => {
                request = null
            })
        }, 5000)

        return () => {
            if (request?.controller && !request.controller.aborted) 
                request.controller.abort()
            clearInterval(interval)
        }
    }, [])

    const confirmCrash = useCallback((id, action) => {
        setBlocked(blockedRef.current.concat(id))
        
        const request = doRequest(api.crash.confirm(id, { action }))
        request.ready.then(() => {
            setCrashList(crashListRef.current.filter(item => item.id !== id))
        })
        .finally(() => setBlocked(blockedRef.current.filter(item => item !== id)))
    }, [])

    return (
        <ModalWindow iconType="warning" className="crashModal" title={translates.crashTitle} open={crashList.length} >
            <Table celled striped>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{translates.date}</Table.HeaderCell>
                        <Table.HeaderCell>{translates.car}</Table.HeaderCell>
                        <Table.HeaderCell>{translates.reason}</Table.HeaderCell>
                        <Table.HeaderCell />
                    </Table.Row>
                </Table.Header>
                <TableBody>
                    {crashList.map(crash => 
                        <Table.Row key={crash.id}>
                            <Table.Cell>{crash.date}</Table.Cell>
                            <Table.Cell>{crash.car}</Table.Cell>
                            <Table.Cell>{intl.formatMessage({ id: `reason.${crash.reason}` })}</Table.Cell>
                            <Table.Cell>
                                <div className="actionColumn">
                                    {crash.actions.map((item, index) => {
                                        let iconName = ''
                                        let label = ''

                                        switch (item.action) {
                                            case 'cancel': iconName = 'cancel'; label = translates.btnCancel; break;
                                            case 'resolve': iconName = 'play'; label = translates.btnResolve; break;
                                            case 'finishHim': iconName = 'flag checkered'; label = translates.btnFinishHim; break;
                                            case 'accept': iconName = 'check circle'; label = translates.btnAccept; break;
                                            default: return null
                                        }

                                        return (
                                            <Popup 
                                                content={intl.formatMessage({ id: `hint.${item.hint}` })}
                                                disabled={item.enabled}
                                                trigger={
                                                    <div className="action">
                                                        <Button 
                                                            key={index} 
                                                            className="infoButton" 
                                                            disabled={!item.enabled} 
                                                            loading={blocked.includes(crash.id)} 
                                                            icon 
                                                            onClick={() => confirmCrash(crash.id, item.action)}
                                                        >
                                                            <Icon name={iconName}/>
                                                            <span>{label}</span>
                                                        </Button>
                                                    </div>
                                                }
                                            />
                                        )
                                    })}
                                </div>
                            </Table.Cell>
                        </Table.Row>
                    )}
                </TableBody>
            </Table>
        </ModalWindow>
    )
}

export default injectIntl(CrashModal)
