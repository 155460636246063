import React from 'react'
import { Icon, Placeholder } from 'semantic-ui-react'

import './Card.css'

export function Card(props) {
    const { loading, className } = props

    const showPlaceHolder = () => {
        return (
            <Placeholder inverted>
                <Placeholder.Header image>
                    <Placeholder.Line />
                    <Placeholder.Line />
                </Placeholder.Header>
            </Placeholder>
        )
    }

    return (
        <div className={className ? `card ${className}` : 'card'}>
            {loading ? showPlaceHolder() : props.children}
        </div>
    )
}

export function CardIcon(props) {
    const { name } = props

    return (
        <span className="cardIcon">
            <Icon name={name} />
        </span>
    )
}

export function CardBody(props) {
    return (
        <div className="cardBody">
            {props.children}
        </div>
    )
}

export function CardActions(props) {
    return (
        <div className="cardActions">
            {props.children}
        </div>
    )
}