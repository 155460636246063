import { Icon } from 'semantic-ui-react'

import './MessageIcon.css'

function MessageIcon (props) {
    const { type, className } = props

    switch (type) {
        case "success":
            return (<Icon className={`iconInfo ${className}`} name="check circle outline"/>)
        case "info":
            return (<Icon className={`iconInfo ${className}`} name="info circle"/>)
        case "plus":
            return (<Icon className={`iconInfo ${className}`} name="plus"/>)
        case "edit":
            return (<Icon className={`iconInfo ${className}`} name="edit"/>)
        case "warning":
            return (<Icon className={`iconWarning ${className}`} name="warning sign"/>)
        case "error":
            return (<Icon className={`iconError ${className}`} name="minus circle"/>)
        case "question":
            return (<Icon className={`iconQuestion ${className}`} name="help circle"/>)
        default:
            return ('')
    }
}

export default MessageIcon
