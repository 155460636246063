import React, { useState, useEffect, useRef, useContext } from 'react'
import { injectIntl } from 'react-intl'

import { Loader } from 'semantic-ui-react'

import { UserContext, NotifyContext } from 'context'
import { Card, CardIcon, CardBody, CardActions } from '../Card'

import Paginator from '../Paginator'
import DeleteModal from './DeleteModal'
import EditModal from './EditModal'

import api from '../../../actions/api'
import doRequest from 'actions/doRequest'
import './UsersPage.css'

import { cardsOnPage, editableRoles } from 'constants/config.json'


function UsersPage (props) {
    const { intl } = props
    const { currentUser, logout } = useContext(UserContext)

    const [loading, setLoading] = useState(false)
    const [userList, setUserList] = useState([])
    const [pageCount, setPageCount] = useState(0)
    const [page, setPage] = useState(1)
    const abortRequest = useRef(null)

    const { pushNotify } = useContext(NotifyContext)

    const loadUserListFromPage = (page = 1) => {
        setUserList([])
        setLoading(true)

        const offset = cardsOnPage * (page - 1), count = cardsOnPage

        const req = doRequest(api.user.list(offset, count), pushNotify, logout)
        abortRequest.current = req.abort

        req.ready.then(({ users, count }) => {
            const pages = Math.ceil(count / cardsOnPage)

            setUserList(users)                
            if (page > pages & pages > 0) {
                loadUserListFromPage(pages)
            } else {
                setPageCount(pages)
                setPage(page)
            }
        }).catch(console.error)
        .finally(() => {
            setLoading(false)
            abortRequest.current = null
        })
    }

    const refresh = () => {
        loadUserListFromPage(page)
    }

    useEffect(() => {
        refresh()

        return () => {
            if (abortRequest.current)
                abortRequest.current()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="users">
            <div className="userBottom"><EditModal type="add" onEditUsers={refresh} hide={loading}/></div>
            {
                loading ?
                    <Loader className={"page-loader"} active inline="centered" />
                :
                    userList.map(user => 
                        <Card key={user.id}>
                            <CardIcon name="user circle"/>
                            <CardBody>
                                <span className="userName">{user.firstName} {user.lastName} <span className="userLogin">({user.login})</span></span>
                                <span className="userRole">{intl.formatMessage({ id: 'user.role.' + user.role })}</span>
                            </CardBody>
                            <CardActions>
                                <EditModal id={user.id} type="edit" onEditUsers={refresh} />    
                                <DeleteModal user={user} onDeleteUser={refresh} disabled={user.login === currentUser.login || !editableRoles.includes(user.role)} />
                            </CardActions>
                        </Card>
                    )
            }
            <Paginator pageCount={pageCount} page={page} setPage={loadUserListFromPage} disabled={loading}/>
        </div>
    )
}

export default injectIntl(UsersPage)
