import React from 'react'
import ReactDOM from 'react-dom'
import 'semantic-ui-css/semantic.min.css'
import './assets/styles/themes.css'
import './index.css'
import App from './components/App'
import ReactModal from 'react-modal'

ReactModal.setAppElement('#root');

ReactDOM.render(
  <App />,
  document.getElementById('root')
)
