import PropTypes from 'prop-types'
import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { UserContext } from 'context'

const UserRoute = ({ component: Component, ...rest }) => {
  const { currentUser } = useContext(UserContext)

  return (
    <Route {...rest} render={props => {
      const loginPage = props.location.pathname === '/login'
      const authorized = currentUser?.auth || false

      if ((authorized && !loginPage) || (loginPage && !authorized)) {
        return <Component {...rest} {...props} />
      } else if (loginPage) {
        return <Redirect to="/" />
      } else {
        return <Redirect to="/login" />
      }
    }} />
  )
}

UserRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.object,
    PropTypes.func
  ]).isRequired,
  isAuthentificated: PropTypes.bool,
  location: PropTypes.object
}

export default UserRoute
