import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'semantic-ui-react'

import { SettingsContext } from 'context'

import './LangSelector.css'

function LangSelector (props) {
  const { full } = props
  const { locale, setLocale, localeList } = useContext(SettingsContext)

  const showLanguage = () => {
    const buttons = []

    for (const element of localeList) {
      buttons.push(
        <Button 
          key={element.locale}
          disabled={element.locale === locale} 
          name={element.locale}
          onClick={e => {setLocale(e.target.name)}}
        >
          {element[full ? 'name' : 'shortName']}
        </Button>)
    }

    return buttons
  }

  return (
    <Button.Group>
      {showLanguage()}
    </Button.Group>
  )
}

LangSelector.propTypes = {
  full: PropTypes.bool
}

export default LangSelector
