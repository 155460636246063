import React, { useContext, useState, useEffect } from 'react'

import { injectIntl } from 'react-intl'
import { UserContext, PdaContext, NotifyContext } from 'context'
import { Table, TableBody, Loader } from 'semantic-ui-react'
import Label from '../Label'
import { Card, CardBody, CardIcon, CardActions } from '../Card'
import DeleteModal from './DeleteModal'
import Paginator from '../Paginator'
import api from 'actions/api'
import doRequest from 'actions/doRequest'

import './QueuePage.css'

import { cardsOnPage } from 'constants/config.json'

const getWaitTime = (time) => {
    const hour = Math.floor(time / 60)
    const min = time % 60

    return `${hour}:${min < 10 ? `0${min}` : min}`
}

function QueuePage(props) {
    const { intl } = props
    const { logout } = useContext(UserContext)
    const { pushNotify } = useContext(NotifyContext)
    const { isPda } = useContext(PdaContext)
    const [loading, setLoading] = useState(true)
    const [queue, setQueue] = useState([])
    const [page, setPage] = useState(1)
    const [pageCount, setPageCount] = useState(1)

    const translates = {
        driver: intl.formatMessage({ id: 'title.driver' }),
        carNumber: intl.formatMessage({ id: 'title.car' }),
        position: intl.formatMessage({ id: 'title.position' }),
        notFound: intl.formatMessage({ id: 'title.recordNotFound' }),
        waitTime: intl.formatMessage({ id: 'title.waitTime' }),
    }

    const loadQueue = (page) => {
        setQueue([])
        setLoading(true)

        const offset = cardsOnPage * (page - 1), count = cardsOnPage
        const req = doRequest(api.queue.list(offset, count), pushNotify, logout)

        req.ready.then(({ queue, count }) => {
            const pages = Math.ceil(count / cardsOnPage)
            
            setQueue(queue)                
            setLoading(false)

            if (page > pages && pages > 0) {
                loadQueue(pages)
            } else {
                setPageCount(pages)
                setPage(page)
            }
        }).catch(console.error)
    }

    useEffect(() => {
        loadQueue(1)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <React.Fragment>
            { loading ? 
                <Loader className={"page-loader"} active inline="centered" />
            :   
                queue.length === 0 ?
                    <Label text={translates.notFound} />
                :
                    isPda ?
                        queue.map((item) => (
                            <Card key={item.id} className="infoCard">
                                <CardIcon name="truck" />
                                <CardBody>
                                    <span><b>{translates.position}:</b> {item.pos || '---'}</span>
                                    <span><b>{translates.carNumber}:</b> {item.carNumber || '---'}</span>
                                    <span><b>{translates.driver}:</b> {item.driver || '---'}</span>
                                    <span><b>{translates.waitTime}:</b> {item.waitTime ? getWaitTime(item.waitTime) : '---'}</span>
                                </CardBody>
                                <CardActions>
                                    <DeleteModal queue={item} onDelete={() => loadQueue(1)}/>
                                </CardActions>
                            </Card>
                        ))
                    :
                        <Table celled striped>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>{translates.position}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.carNumber}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.driver}</Table.HeaderCell>
                                    <Table.HeaderCell>{translates.waitTime}</Table.HeaderCell>
                                    <Table.HeaderCell></Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <TableBody>
                                {
                                    queue.map((item) => (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.pos || '---'}</Table.Cell>
                                            <Table.Cell>{item.carNumber || '---'}</Table.Cell>
                                            <Table.Cell>{item.driver || '---'}</Table.Cell>
                                            <Table.Cell>{item.waitTime ? getWaitTime(item.waitTime) : '---'}</Table.Cell>
                                            <Table.Cell className="actionColumn"><DeleteModal queue={item} onDelete={() => loadQueue(1)}/></Table.Cell>
                                        </Table.Row>
                                    ))
                                }
                            </TableBody>
                        </Table>
            }
            <Paginator pageCount={pageCount} page={page} setPage={loadQueue} disabled={loading} />
        </React.Fragment>
    )
}

export default injectIntl(QueuePage)
