import React, { useMemo } from 'react'
import { injectIntl } from 'react-intl'
import LangSelector from '../../LangSelector'
import ThemeSelector from '../../ThemeSelector'

import './SettingsPage.css'

function SettingsPage(props) {
    const { intl } = props
    const translates = useMemo(() => {
        return {
            language: intl.formatMessage({ id: 'title.language' }),
            theme: intl.formatMessage({ id: 'title.theme' })
        }
    }, [intl.locale]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="settingsContainer">
            <div className="setting">
                <span>{translates.language}:</span><LangSelector full/>
            </div>
            <div className="setting">
                <span>{translates.theme}:</span><ThemeSelector />
            </div>
        </div>
    )
}

export default injectIntl(SettingsPage)