import React from 'react'
import MessageIcon from '../MessageIcon'
import { Modal, Button } from 'semantic-ui-react'

import './ModalWindow.css'

function ModalWindow (props) {
  const {title, open, onOpen, onClose, iconType, trigger, actions, scrolling, className} = props

  return (
    <Modal trigger={trigger} open={Boolean(open)} onOpen={onOpen} onClose={onClose} className={className}>
      <Modal.Header>
        <MessageIcon type={iconType} className="icon" />
        {title}
      </Modal.Header>
      <Modal.Content scrolling={scrolling}>
        {props.children}
      </Modal.Content>
      <Modal.Actions>
        {actions?.map((action, index) => <Button key={index} disabled={action.disabled} loading={action.loading} onClick={action.onClick} color={action.color}>{action.title}</Button>)}
      </Modal.Actions>
    </Modal>
  )
}

export default ModalWindow