import React from 'react'
import { Checkbox } from 'semantic-ui-react'

import './CheckBoxPicker.css'

function CheckBoxPicker(props) {
    // TODO: do return values

    const { value, options, onChange, className } = props
    const getClassName = "checkbox picker" + (className ? ' ' + className : '')

    const changeHandler = (e, { checked, name }) => {
        if (checked) {
            onChange(value ? value.concat(name) : [name])
        } else { 
            onChange(value.filter(item => item !== name))
        }
    }

    return (
        <div className={getClassName}>
            {options.map(item => {
                return (
                    <Checkbox key={item.key} name={item.value} className="checkbox item" label={item.text} onChange={changeHandler} checked={value?.includes(item.value)}/>
                )
            })}
        </div>
    )
}

export default CheckBoxPicker
