import React from 'react'
import DatePicker from 'react-date-picker'
import { injectIntl } from 'react-intl'

import './DateRangePicker.css'

function DateRangePicker(props) {
    const { value, onChange, intl, className } = props
    const getClassName = "date range" + (className ? ' ' + className : '')
    const translates = {
        from: intl.formatMessage({ id: 'title.from' }),
        to: intl.formatMessage({ id: 'title.to' })
    }

    return (
        <div className={getClassName}>
            <div className="date range item">
                <span className="label">{translates.from}</span>
                <DatePicker value={value?.start} onChange={start => onChange({ ...value, start })} maxDate={value?.end || new Date()} />
            </div>
            <div className="date range item">
                <span className="label">{translates.to}</span>
                <DatePicker value={value?.end} onChange={end => onChange({ ...value, end })} minDate={value?.start} maxDate={new Date()} />
            </div>
        </div>
    )
}

export default injectIntl(DateRangePicker)
