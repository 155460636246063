import React from 'react'
import PropTypes from 'prop-types'
import Notify from './Notify'

import './NotifyBlock.css'

function NotifyBlock (props) {
  const { notifyes, deleteNotifyAction } = props

  const getNotifyList = (notifyes) => {
    const list = []

    if (notifyes) {
      notifyes.forEach(notify => {
        list.push(<Notify key={notify.id} notify={notify} deleteNotifyAction={deleteNotifyAction}/>)
      })
    }

    return list
  }

  return (
    <div className="notifyBlock">
      {getNotifyList(notifyes)}
    </div>
  )
}

NotifyBlock.propTypes = {
  notifyes: PropTypes.array,
  deleteNotifyAction: PropTypes.func
}

export default NotifyBlock
