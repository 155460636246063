import React, { useEffect, useState } from 'react'
import { injectIntl } from 'react-intl'
import DateRangePicker from './DateRangePicker'
import CheckBoxPicker from './CheckBoxPicker'
import DropDownPicker from './DropDownPicker'
import ModalWindow from '../../../components/ModalWindow'
import { Button } from 'semantic-ui-react'

import './Filter.css'

function Filter (props) {
    const { filterList, filter, setFilter, disabled, intl } = props
    const [state, setState] = useState({})
    const [open, setOpen] = useState(false)

    const translates = {
        filter: intl.formatMessage({ id: 'title.filter' }),
        accept: intl.formatMessage({ id: 'btn.accept' }),
        cancel: intl.formatMessage({ id: 'btn.cancel' })
    }

    const acceptHandler = () => {
        setFilter && setFilter(state)
        setOpen(false)
    }

    const cancelHandler = () => {
        setState(filter)
        setOpen(false)
    }

    const getButtons = () => {
        return [
            { title: translates.accept, onClick: acceptHandler, disabled: false, loading: false },
            { title: translates.cancel, onClick: cancelHandler, disabled: false, loading: false },
        ]
    }

    const getField = (field) => {
        switch (field.type) {
            case 'daterange': {
                return <DateRangePicker className="field" value={state[field.name]} onChange={value => setState({ ...state, [field.name]: value })} />
            }
            case 'checkbox': {
                return <CheckBoxPicker className="field" value={state[field.name]} options={field.options} onChange={value => setState({ ...state, [field.name]: value })} />
            }
            case 'dropdown': {
                return <DropDownPicker className="field" value={state[field.name]} options={field.options} onChange={value => setState({ ...state, [field.name]: value })} />
            }
            default: 
                console.error('Not found filter type: ', field.type)
        }
    }

    useEffect(() => {
        setState(filter)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    
    return (
        <ModalWindow 
            iconType="info"
            title={translates.filter} 
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            trigger={
                <Button disabled={disabled} content={translates.filter} icon="filter" labelPosition="left" />
            }
            actions={getButtons()}
        >
            {filterList.map((field) => {
                return (
                    <div key={field.name} className="filter row">
                        <span className="title">{field.title}:</span>
                        { getField(field) }
                    </div>
                )
            })}
        </ModalWindow>
    )
}

export default injectIntl(Filter)
