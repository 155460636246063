import React, { useContext, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { UserContext, NotifyContext } from 'context'
import { Button, Form, Input, Icon } from 'semantic-ui-react'

import api from '../../actions/api'
import { bots } from '../../constants/config.json'

import './LoginPage.css'
import UserSelectModal from './UserSelectModal'

function LoginPage (props) {
  // Проблеми перекладів
  const { intl } = props
  const { setUser } = useContext(UserContext)
  const translates = {
    loginInput: intl.formatMessage({ id: 'user.login' }),
    passInput: intl.formatMessage({ id: 'user.password' }),
    loginButton: intl.formatMessage({ id: 'btn.login' }),
    botLink: {
      telegram: intl.formatMessage({ id: 'title.telegramBotLink' }),
      viber: intl.formatMessage({ id: 'title.viberBotLink' })
    }
  }

  const [loginError, setLoginError] = useState(false)
  const [passError, setPassError] = useState(false)
  const [authError, setAuthError] = useState(false)
  const [loading, setLoading] = useState({ isLoading: false, abortAction: () => {} })

  const [loginInput, setLogin] = useState('')
  const [passInput, setPass] = useState('')

  const [userList, setUserList] = useState([])

  const loginRef = useRef()
  const passRef = useRef()
  const pinTimerRef = useRef()

  const [generatedAuthPin, setGeneratedAuthPin] = useState(false)

  const { pushNotify } = useContext(NotifyContext)

  const disableShake = () => {
    if (loginError) setLoginError(false)
    if (passError) setPassError(false)
    if (authError) setAuthError(false)
  }

  const isShaking = () => {
    return loginError || passError || authError
  }

  useEffect(() => {
    if (isShaking) {
      const shakeTimer = setTimeout(disableShake, 400)
      return () => clearTimeout(shakeTimer)
    }
  })

  const doAuth = (userId) => {
    console.log(userId)

    if (loginInput?.length === 0) {
      pushNotify('error', 'title.error', 'msg.loginUncorrect')
      setLoginError(true)
    } else if (generatedAuthPin) {
      if (passInput?.length === 0) {
        pushNotify('error', 'title.error', 'msg.passUncorrect')
        setPassError(true)
      } else {
        const creditionals = { login: loginInput, pass: passInput, id: userId }

        const request = api.authorization.login(creditionals)
        setLoading({ isLoading: true, abortAction: request.abort })

        request.ready.then(response => {
          const { status, data } = response
          setLoading({ isLoading: false })

          if (status === 200) {
            if (data.userList) {
              setUserList(data.userList)
            } else {
              setUser({
                ...data.user,
                login: loginInput,
                auth: true
              })
            }
          } else {
            pushNotify('error', 'title.error', ('msg.' + data.text))
            setAuthError(true)
          }
        }).catch(e => {
          pushNotify('error', 'title.error', 'msg.conectionError')
          setLoading({ isLoading: false })
        })
      }
    } else {
      const creditionals = { login: loginInput }
      const request = api.authorization.generateAuthPin(creditionals)

      setLoading({ isLoading: true, abortAction: request.abort})
      request.ready.then(response => {
        const { status, data } = response
        setLoading({ isLoading: false })

        if (status === 200) {
          setGeneratedAuthPin(true) 
          setPass('')
          passRef.current.focus()
          pushNotify('info', 'title.success', 'msg.authPinIsGenerated', 10000)
          
          pinTimerRef.current = setTimeout(() => {
            setGeneratedAuthPin(false)
          }, 120000)
        } else {
          pushNotify('error', 'title.error', ('msg.' + data.text))
          setAuthError(true)
        }
      }).catch(e => {
        pushNotify('error', 'title.error', 'msg.conectionError')
        setLoading({ isLoading: false })
      })
    }
  }

  const handleAuthSubmit = (event) => {
    doAuth()

    event.preventDefault()
  }

  const handleAuthAction = (userId) => {
    doAuth(userId)
  }

  const onLoginChangeHandler = (e) => {
    setLogin(e.target.value)
    setGeneratedAuthPin(false)
    if (pinTimerRef.current) clearTimeout(pinTimerRef.current)
  }

  return (
    <Form onSubmit={handleAuthSubmit} className="auth-form">
      <div className="container">
        <div className="logoImage" />
        <div className={'login-inputs animated' + (authError ? ' shake' : '')}>
          <Input 
            icon="user" 
            ref={loginRef}
            iconPosition="left"
            error={loginError || authError}
            placeholder={translates.loginInput} 
            className={'animated' + (loginError ? ' shake' : '')} 
            value={loginInput}
            onChange={onLoginChangeHandler} 
          />
          <Input 
            icon="key" 
            ref={passRef}
            iconPosition="left"
            error={passError || authError}
            type="password" 
            placeholder={translates.passInput} 
            className={'animated' + (passError ? ' shake' : '')} 
            value={passInput}
            onChange={e => setPass(e.target.value)} 
            disabled={!generatedAuthPin}
          />
          <Button color='yellow' disabled={loginError || passError || authError || loading.isLoading} loading={loading.isLoading}>
            {translates.loginButton}
          </Button>
        </div>
      </div>
      {bots.map((bot, index) => 
        <span key={index} className="botLink"><a href={bot.url} rel="noopener noreferrer" target="_blank"><Icon name={bot.icon} />{translates.botLink[bot.type]}</a></span>
      )}
      <UserSelectModal userList={userList} onCancel={() => setUserList([])} onAccept={handleAuthAction} loading={loading.isLoading}/>
    </Form>
  )
}

LoginPage.propTypes = {
  intl: PropTypes.object
}

export default injectIntl(LoginPage)
